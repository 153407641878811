.maincalendar .MuiDayPicker-header{
  background-color: #EE7404;
}

.maincalendar .MuiDayPicker-header >span{
  color: #fff;
  font-weight: bold;
}

.maincalendar .MuiPickerStaticWrapper-content{
  border-radius: 10px;
  overflow: auto;
}

.maincalendar .MuiPickersCalendarHeader-root{
  margin: 0px;
  padding: 5px 0px;
}

.maincalendar .MuiDayPicker-weekContainer >button {
  border-radius: 0px;
}
.maincalendar .MuiPickersCalendarHeader-labelContainer{
  margin: 0 auto;
  position: relative;
  z-index: 9;
}
.maincalendar{
  position: relative;
}
.maincalendar .MuiPickersArrowSwitcher-root{
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.maincalendar .MuiDayPicker-weekContainer button[aria-selected="false"]{
  border: 1px solid #ddd;
}
.maincalendar .MuiDayPicker-weekContainer button[aria-selected="true"]{
  border: 1px solid transparent;
}
@media screen and (max-width:1300px) {
  .maincalendar .MuiPickerStaticWrapper-content{
    width: 100%;
    min-width: 100%;
  }
  .maincalendar .MuiPickerStaticWrapper-content .MuiCalendarOrClockPicker-root>div>div{
    width: 100%;
    
  }
}